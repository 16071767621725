import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { getRoute } from '../../services/routes.service';
import { ImageBehaviour, Images } from './productsComponent/Images';
import { Price } from './productsComponent/Price';
import useWindowSize from '../../hooks/UseWindowSize';
import { forwardRef } from 'react';
import { translateText } from '../../services/translation.service';
import { UserContext } from '../../context/user-context';
import AdProduct from './productsComponent/AdProduct';
import GoogleFeedAd from 'src/adsense/GoogleFeedAd';
import GoogleMultiplexAd from 'src/adsense/GoogleMultiplexAd';
import GoogleAutoAd from 'src/adsense/GoogleAutoAd';

interface IProps {
  products: IProduct[];
  style: string;
  id?: string;
}

const CardProducts = (props: IProps, ref: React.Ref<HTMLDivElement>) => {
  const { authorized } = React.useContext(UserContext);
  const textToCopyRef = React.useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  let size = useWindowSize();
  const isMobile = size.width < 770;

  const handleCopy = () => {
    if (textToCopyRef.current) {
      const text = textToCopyRef.current.textContent || '';
      navigator.clipboard.writeText(text);
    }
  };

  const showAd = () => {
    return(
      !isMobile && <GoogleFeedAd/>
    )
  }

  const viewProducts = Array.isArray(props.products)
    ? props.products.map((p, index) => {
        const isSale = p.salePrice > 0;
        var difference = Math.round((p.salePrice / p.price) * 100);
        var roundedDifference = Math.round(difference / 5) * 5;
        var percentage = 100 - roundedDifference;

        return (
          <React.Fragment key={index}>
            {index > 0 && index % 15 === 0 && <AdProduct/>}
            {index > 0 && index % 12 === 0 && showAd()}
            <div className='productCard'>
              <div className="productImagePos" onClick={() => navigate(getRoute(`product/${p.id}`))}>
                {isSale ? <div className='percentageIcon'><span className='percentageNumber'>-{percentage}%</span></div> : <></>}
                <Images images={p.images} behaviour={ImageBehaviour.Single} />
              </div>
              <div className='productLabel' key={index} onClick={() => navigate(getRoute(`product/${p.id}`))}>
                <Price product={p} font='xl' currTypeBack={translateText("uah|A")} />
                {isMobile ? <></> : <div className='productLabelOverflow'>{translateText(p.labelName)} {p.brand}</div>}
              </div>
              {authorized ?  
                <div>
                  <span ref={textToCopyRef} className='hidden'>{p.id}</span>
                  <button className='bg-transparent text-black p-1 rounded-lg' onClick={handleCopy}>copy id</button>
                </div>
                : undefined}
            </div>
          </React.Fragment>
        );
      })
    : null;

  return (
    <div className={`${props.style}`} ref={ref}>
      {viewProducts}
    </div>
  );
}

export default forwardRef(CardProducts);
